import { render, staticRenderFns } from "./CarItem.vue?vue&type=template&id=9000ff04&scoped=true&"
import script from "./CarItem.vue?vue&type=script&lang=js&"
export * from "./CarItem.vue?vue&type=script&lang=js&"
import style0 from "./CarItem.vue?vue&type=style&index=0&id=9000ff04&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9000ff04",
  null
  
)

export default component.exports