<template>
    <div class="info">
        <car-item v-for="(item, index) in dataList" :key="index" :data="item" :date="item.date || date" />
    </div>
</template>

<script>
import CarItem from './CarItem.vue';
import { fetchPortal } from '../util';

export default {
    components: {
        CarItem
    },
    props: {
        date: {
            type: String
        }
    },
    data() {
        return {
            dataList: []
        };
    },
    created() {
        fetchPortal('nfp-website-data')('carInfo').then(res => {
            this.dataList = res;
        });
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.info {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
