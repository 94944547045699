<template>
    <div class="top">
        <div class="content">
            <div class="logo"></div>
            <div class="nav">
                <div :class="isHome ? 'active' : ''" @click="changePage('home')">首页</div>
                <div :class="!isHome ? 'active' : ''" @click="changePage('info')">信息公示</div>
                <div @click="changePage('owner')">货主登录</div>
                <div @click="changePage('platform')">平台登录</div>
                <div class="download" @click="toggle">
                    App下载
                    <div class="qr-code" v-if="qrCodeIsShow">
                        <img :src="qrCodeUrl" alt="" />
                        <div class="title">司机端下载</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchPortal, reportLx } from '../util';

export default {
    data() {
        return {
            isHome: true,
            qrCodeUrl: '',
            hzUrl: 'https://hz.zhuoluwl.com',
            ptUrl: 'https://pt.zhuoluwl.com',
            qrCodeIsShow: false
        };
    },
    created() {
        fetchPortal('nfp-website-data')('qrCodeUrl').then(res => {
            this.qrCodeUrl = res;
        });
    },
    methods: {
        changePage(nav) {
            if (nav === 'home') {
                this.isHome = true;
                reportLx('moduleClick', 'b_youxuan_b_s30dyc4o_mc');
                this.$emit('change-page', 'home');
            } else if (nav === 'info') {
                this.isHome = false;
                reportLx('moduleClick', 'b_youxuan_b_7iqlgum0_mc');
                this.$emit('change-page', 'scoreInfo');
            } else if (nav === 'owner') {
                reportLx('moduleClick', 'b_youxuan_b_7jr6ookl_mc');
                window.location.href = this.hzUrl;
            } else if (nav === 'platform') {
                reportLx('moduleClick', 'b_youxuan_b_fl86cr99_mc');
                window.location.href = this.ptUrl;
            }
        },
        toggle () {
            this.qrCodeIsShow = !this.qrCodeIsShow;
            if (this.qrCodeIsShow) {
                reportLx('moduleClick', 'b_youxuan_b_kj907h0i_mc');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.top {
    // width: 100vw;
    width: 100%;
    background-color: #ffd100;
    .content {
        width: 1000px;
        background-color: #ffd100;
        height: 60px;
        margin: 0 auto;
        .logo {
            float: left;
            width: 154px;
            height: 60px;
            font-size: 20px;
            color: #3d2500;
            text-align: right;
            font-weight: 400;
            line-height: 60px;
            background-image: url('https://p0.meituan.net/travelcube/bcc6fb08b9db012a15270f82cb49793b2545.png');
            background-position: 0 14px;
            background-size: 154px 32px;
            background-repeat: no-repeat;
        }
        .nav {
            float: right;
            display: flex;
            & > div {
                margin-left: 24px;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.72);
                text-align: center;
                font-weight: 400;
                line-height: 60px;
                box-sizing: border-box;
                a {
                    color: rgba(0, 0, 0, 0.72);
                }
            }
            & > div:hover {
                cursor: pointer;
            }
            .active {
                color: rgba(0, 0, 0, 0.84);
                text-align: center;
                font-weight: 500;
                height: 60px;
                border-bottom: 4px solid rgba(0, 0, 0, 0.84);
            }
            .download {
                position: relative;
                .qr-code {
                    position: absolute;
                    z-index: 10;
                    width: 126px;
                    height: 150px;
                    top: 51px;
                    left: -63px;
                    margin-left: 50%;
                    background: #ffffff;
                    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02),
                        0 2px 6px 0 rgba(0, 0, 0, 0.06);
                    border-radius: 4px;
                    &::after {
                        content: '';
                        position: absolute;
                        top: -6px;
                        left: 63px;
                        border-width: 0 6px 6px 6px;
                        border-style: solid;
                        border-color: #fff transparent;
                    }
                    img {
                        width: 94px;
                        height: 94px;
                        margin: 16px;
                    }
                    .title {
                        position: absolute;
                        bottom: 15px;
                        width: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.87);
                        line-height: 22px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>
