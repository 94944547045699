import Vue from 'vue';
import { Button, Select, Carousel, CarouselItem, Option, Table, TableColumn } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css';
import './styles/root.scss';
import App from './App.vue';

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Option.name, Option);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.config.productionTip = false;

new Vue({
    render: h => h(App)
}).$mount('#app');
