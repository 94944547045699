const CachePromise = {};

// 上报请求信息
function sendCatApi(url, code, responseTime, content) {
    if (window.Owl) {
        try {
            let statusCode = code;

            if ([undefined, null].includes(statusCode)) {
                statusCode = 10001;
            } else if (+statusCode === 0) {
                statusCode = 10000;
            }
            window.Owl.addApi({
                name: url,
                statusCode,
                responseTime,
                content
            });
        } catch (e) {
            window.console.error(e);
        }
    }
}

export const reportLx = (...args) => {
    if (window.LXAnalytics) {
        window.LXAnalytics(...args);
    } else {
        console.log(...args);
    }
};

// 上报异常信息
function sendCatErrorApi(url, code, error, res = {}) {
    if (window.Owl) {
        try {
            window.Owl.addError(
                {
                    name: 'PORTAL_RESPONSE_ERROR',
                    msg: `${error && error.stack} \n Response: ${
                        JSON.stringify(res) || new Error('@yxfe/tools: FetchPortal js error').stack
                    }`
                },
                {
                    category: 'ajaxError',
                    level: 'info',
                    tags: { url, code, error }
                }
            );
        } catch (e) {
            window.console.error(e);
        }
    }
}

export const fetchPortal = (mod = '') => {
    const isProd = ['prod'].includes(process.env.VUE_APP_ENV);
    const urlPrefix = '//portal-portm.meituan.com/horn/v1/modules';
    const apiName = isProd ? 'prod' : 'test';
    const url = [urlPrefix, mod, apiName].join('/');
    const startTime = Date.now();
    let resCopy = {}; // copy response;
    if (!CachePromise[mod]) {
        // CachePromise[mod] = ajax.get(url);
        CachePromise[mod] = fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    sendCatApi(url, 0, Date.now() - startTime);
                } else {
                    sendCatApi(url, res.status, Date.now() - startTime);
                }
                return res;
            })
            .then(res => {
                if (res && res.json) {
                    if (typeof res.clone === 'function') {
                        resCopy = res.clone();
                    }
                    return res.json();
                }
                return res;
            })
            .catch(e => {
                const { text, json } = resCopy;
                const fn = text || json;
                if (typeof fn === 'function') {
                    fn.call(resCopy).then(body => {
                        sendCatErrorApi(url, resCopy.status, e, {
                            body: typeof body === 'string' ? body : JSON.stringify(body),
                            code: resCopy.status
                        });
                    });
                } else {
                    throw e;
                }
            });
    }
    return (key = '') => {
        return CachePromise[mod].then(res => {
            return key ? res[key] : res;
        });
    };
};

// 日期格式化
const WeekTextMap = ['天', '一', '二', '三', '四', '五', '六'];

// YYYY-MM-DD
// YYYY-MM-DD HH:mm
// YYYY-MM-DD HH:mm:ss
export const formatTime = (date, format = 'yyyy-MM-dd', invalidText = '--') => {
    if (+date <= 0) {
        return invalidText;
    }
    const dt = new Date(+date);
    const parse = {
        yyyy: dt.getFullYear(),
        MM: dt.getMonth() + 1,
        dd: dt.getDate(),
        HH: dt.getHours(),
        mm: dt.getMinutes(),
        ss: dt.getSeconds(),
        星期: `星期${WeekTextMap[dt.getDay()]}`
    };

    parse.YYYY = parse.yyyy;
    parse.DD = parse.dd;

    Object.entries(parse).forEach(([k, v]) => {
        parse[k] = String(v).padStart(2, 0);
    });

    return Object.entries(parse).reduce((prev, [k, v]) => {
        return prev.replace(k, v);
    }, format);
};
