<template>
    <div class="info">
        <goods-item v-for="(item, index) in dataList" :key="index" :data="item" :date="item.date || date" />
    </div>
</template>

<script>
import GoodsItem from './GoodsItem.vue';
import { fetchPortal } from '../util';

export default {
    components: {
        GoodsItem
    },
    props: {
        date: {
            type: String
        }
    },
    data() {
        return {
            dataList: []
        };
    },
    created() {
        fetchPortal('nfp-website-data')('goodsInfo').then(res => {
            this.dataList = res;
        });
    },
    methods: {
        changePage(nav) {
            if (nav === 'goods') {
                this.isGoods = true;
            } else {
                this.isGoods = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.info {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
