<template>
    <div class="item">
        <div class="city">
            {{ data.startAddress + '-' + data.endAddress }}
        </div>
        <div class="row">
            <div class="label">车长：</div>
            {{ data.carSize }}
            <div class="label">车型：</div>
            {{ data.carType }}
        </div>
        <div class="row">
            <div class="label">货物名称：</div>
            {{ data.goods }}
        </div>
        <div class="row">
            <div class="label">几装几卸：</div>
            {{ data.load }}
        </div>
        <div class="row">
            <div class="label">装车时间：</div>
            {{ date }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            require: true
        },
        date: {
            type: String,
            require: true
        }
    },
    data() {
        return {
            // data: {
            //     startAddress: '邢台市',
            //     endAddress: '聊城市',
            //     carSize: '6.2米',
            //     carType: '高栏',
            //     goods: '废铝 (16~16.5吨)',
            //     load: '多装装卸',
            //     date: '2021-10-19'
            // }
        };
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.item {
    width: 322px;
    padding: 20px 0 14px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 17px;
    text-align: left;
    .city {
        margin-left: 16px;
        margin-bottom: 10px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.84);
        line-height: 16px;
        font-weight: 500;
    }
    .row {
        padding: 6px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.84);
        line-height: 14px;
        font-weight: 500;

        .label {
            margin-left: 16px;
            display: inline;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400;
        }
    }
}
</style>
