<template>
    <div class="content">
        <div class="tab">
            <div :class="isGoods ? 'active' : ''" @click="changePage('goods')">货源信息</div>
            <div :class="!isGoods ? 'active' : ''" @click="changePage('car')">车源信息</div>
        </div>
        <component v-bind:is="currentTabComponent" :date="date" />
    </div>
</template>

<script>
import CarInfoVue from './CarInfo.vue';
import GoodsInfoVue from './GoodsInfo.vue';

export default {
    props: {
        date: {
            type: String
        }
    },
    data() {
        return {
            isGoods: true,
            currentTabComponent: GoodsInfoVue
        };
    },
    methods: {
        changePage(nav) {
            if (nav === 'goods') {
                this.isGoods = true;
                this.currentTabComponent = GoodsInfoVue;
            } else {
                this.isGoods = false;
                this.currentTabComponent = CarInfoVue;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.content {
    min-height: calc(100vh - 60px - 500px - 50px);
    width: 1000px;
    margin: 0 auto;
    background-color: #f2f2f2;
    .tab {
        text-align: center;
        padding: 24px 0 16px;
        height: 41px;
        & > div {
            display: inline-block;
            margin: 0 8px;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.72);
            text-align: center;
            font-weight: 400;
            line-height: 41px;
            box-sizing: border-box;
        }
        & > div:hover {
            cursor: pointer;
        }
        .active {
            color: rgba(0, 0, 0, 0.84);
            text-align: center;
            font-weight: 500;
            height: 41px;
            border-bottom: 4px solid rgba(0, 0, 0, 0.84);
        }
    }
}
</style>
