<template>
    <div class="footer">
        <div class="record">
            <a href="https://beian.miit.gov.cn"> 津ICP备2021007321号-1 </a>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
    color: #000;
    .record {
        width: 1000px;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.84);
        text-align: center;
        font-weight: 500;
        line-height: 50px;
        a:hover {
            color: #fe8c00;
        }
    }
}
</style>
