<template>
    <div class="content">
        <div class="header">
            <div class="title">
                {{ value ? selectList.filter(item => item.value === value)[0].label : '' }}
            </div>
            <div class="text">
                <div class="tip">
                    温馨提示：违规抢单，或定向指派接单后，司机逾期不进行相关退回操作，系统将禁止接单若干日，并自动撤销该单据
                </div>
                <div class="date">公告日期：{{ date }}</div>
            </div>
            <div class="score-info-rule" v-if="value !== 4">
                <div class="rule-text" v-html="ruleText" />
            </div>
            <el-select v-model="value" placeholder="请选择" class="select">
                <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="main">
            <div class="main-img" v-if="value === 4">
                <div class="server-number">投诉途径包括：电话投诉（服务电话：010-57376600）、在线投诉（卓鹿APP）。</div>
                <img src="https://p0.meituan.net/travelcube/bead8a7c2f2e6245c821adfe5a6c8c5c56715.png" alt="" />
            </div>
            <div class="main-table" v-else>
                <div class="table-head">
                    <div v-for="(item, index) in config" :key="index" :style="styleFormatter(item)">
                        {{ item.label }}
                    </div>
                </div>
                <div
                    ref="table"
                    :class="{ anim: animate == true, 'data-scroll': true }"
                    @mouseenter="clearScroll"
                    @mouseleave="openScroll"
                >
                    <div v-for="(item, index) in dataList" :key="index" class="table-body" :style="{ height }">
                        <div v-for="(col, index1) in config" :key="index1" :style="styleFormatter(col)">
                            {{ item[col.prop] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchPortal } from '../util';
import { selectList, ruleConfig, tableConfig } from '../config';

export default {
    props: {
        date: {
            type: String
        }
    },
    data() {
        return {
            selectList,
            config: [],
            dataList: [],
            value: 1,
            ruleTitle: ruleConfig.scoreInfo.ruleTitle,
            ruleText: ruleConfig.scoreInfo.ruleText,
            animate: false,
            timer: undefined,
            height: '40px'
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.config = tableConfig[this.value - 1];
                if (val === 2) this.height = '50px';
                else this.height = '40px';
                switch (val) {
                    case 1:
                        this.getData('scoreInfo');
                        this.openScroll();
                        break;
                    case 2:
                        this.getData('scoreInfoRate');
                        this.openScroll();
                        break;
                    case 3:
                        this.clearScroll();
                        this.getData('scoreInfoDay');
                        break;
                    default:
                        this.clearScroll();
                        break;
                }
            },
            deep: true
        }
    },
    mounted() {
        this.openScroll();
    },
    methods: {
        getData(type) {
            fetchPortal('nfp-website-data')(type).then(res => {
                this.dataList = res;
            });
            this.ruleTitle = ruleConfig[type].ruleTitle;
            this.ruleText = ruleConfig[type].ruleText;
        },
        scroll() {
            if (![1, 2].includes(this.value)) return;
            const table = this.$refs.table;
            table.style.marginTop = `-${this.height}`;
            this.animate = !this.animate;
            setTimeout(() => {
                this.dataList.push(this.dataList.shift());
                this.animate = !this.animate;
                table.style.marginTop = '0px';
            }, 800);
        },
        clearScroll() {
            clearInterval(this.timer);
            this.timer = undefined;
        },
        openScroll() {
            if (![1, 2].includes(this.value)) return;
            !this.timer && (this.timer = setInterval(this.scroll, 1000));
        },
        styleFormatter(col) {
            let res = { flex: 1 };
            if (col.width) res = { width: col.width };
            return {
                ...res,
                'text-align': col.align
            };
        }
    }
};
</script>

<style scoped lang="scss">
.content {
    min-height: calc(100vh - 60px - 50px);
    width: 1000px;
    margin: 0 auto;
    .header {
        text-align: center;
        position: relative;
        .title {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.84);
            text-align: center;
            font-weight: 500;
            line-height: 25px;
            padding-top: 37px;
        }
        .text {
            width: 1000px;
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            .tip {
                font-size: 12px;
                color: #ff8800;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 17px;
            }
            .date {
                font-size: 12px;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 17px;
            }
        }
        .select {
            position: absolute;
            width: 200px;
            top: 33px;
            left: 0;
            /deep/ .el-input__inner {
                height: 32px;
                line-height: 32px;
            }
            /deep/ .el-input__icon {
                line-height: 32px;
            }
        }
    }
    .main {
        width: 1000px;
        margin: 0 auto;
        .server-number {
            font-size: 12px;
            line-height: 17px;
            text-align: left;
            margin-bottom: 20px;
        }
        img {
            width: 1000px;
            height: auto;
        }
        .main-table {
            box-sizing: border-box;
            max-height: 440px;
            overflow: hidden;
            .data-scroll {
                width: 100%;
            }
            .table-head {
                background-color: #d3d3d3;
                position: relative;
                z-index: 3;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            .table-body {
                background-color: #fff;
                border-bottom: 1px solid #ebeef5;
                font-size: 12px;
            }
            .table-head,
            .table-body {
                box-sizing: border-box;
                display: flex;
                width: 100%;
                padding: 8px 0;
            }
        }
    }
}
.anim {
    transition-property: margin-top;
    transition-duration: 1s;
}
</style>
<style lang="scss">
.score-info-rule {
    width: 1000px;
    margin: 20px 0;
    text-align: left;
    position: relative;
    font-size: 12px;
    p {
        margin: 0;
        line-height: 24px;
    }
    .rule-table {
        margin-top: 16px;
        text-align: center;
        border-collapse: collapse;
        border: 1px solid #ccc;
        td,
        th {
            border: 1px solid #ccc;
            padding: 5px 12px;
        }
        .bg-ddd {
            background-color: #ddd;
        }
    }
}
</style>
