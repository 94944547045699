<template>
    <div id="app">
        <top-nav @change-page="changePage" />
        <div class="carousel" v-if="title === 'home'">
            <el-carousel height="500px" :interval="5000">
                <el-carousel-item v-for="(item, index) in carouselConfig" :key="index">
                    <img :src="item.url" alt="" class="image" />
                    <div class="text">
                        <div class="title">{{ item.title }}</div>
                        <div class="info">{{ item.text }}</div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <component v-bind:is="currentTabComponent" :date="date"></component>
        <footer-info />
    </div>
</template>

<script>
import FooterInfo from './components/FooterInfo.vue';
import HomePage from './components/HomePage.vue';
import ScoreInfo from './components/ScoreInfo.vue';
import TopNav from './components/TopNav.vue';
import { fetchPortal, formatTime } from './util';

export default {
    name: 'App',
    components: {
        TopNav,
        FooterInfo
    },
    data() {
        return {
            title: 'home',
            currentTabComponent: HomePage,
            date: formatTime(new Date(), 'yyyy-MM-dd'),
            carouselConfig: [
                {
                    title: '卓鹿',
                    text: '致力于打造国内领先的互联网货运平台',
                    url: 'https://p0.meituan.net/travelcube/5fc56e51d5ab45c892241434102ee59c203455.png'
                },
                {
                    title: '智能匹配',
                    text: '通过多年数据积累，通过大数据算法与深度学习技术，实现车货匹配，提高运输效能。',
                    url: 'https://p0.meituan.net/travelcube/b0db0473e4ea8ffba5691e5cf7f9125d392604.png'
                },
                {
                    title: '透明定价',
                    text: '各项成本清晰透明，没有信息费、管理费、会员费',
                    url: 'https://p0.meituan.net/travelcube/9c84262e52091eb970cb926ef716bf981110681.png'
                }
            ]
        };
    },
    created() {
        fetchPortal('nfp-website-data')('date').then(res => {
            this.date = res.slice(0, 10);
        });
    },
    methods: {
        changePage(title) {
            this.title = title;
            if (title === 'home') {
                this.currentTabComponent = HomePage;
            } else {
                this.currentTabComponent = ScoreInfo;
            }
        }
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #f5f5f5;
    .carousel {
        background-color: #234578;
        height: 500px;
        min-width: 1000px;
        text-align: left;
        .el-carousel--horizontal {
            overflow: hidden;
        }
        .image {
            display: inline-block;
            min-width: 1440px;
            width: 100%;
            min-height: 500px;
            position: absolute;
            z-index: -1;
        }
        .text {
            width: 1000px;
            margin: 0 auto;
        }
        .title {
            margin-top: 147px;
            font-size: 50px;
            color: #ffffff;
            font-weight: 500;
            line-height: 70px;
        }
        .info {
            width: 408px;
            margin-top: 24px;
            font-size: 24px;
            color: #ffffff;
            font-weight: 400;
            line-height: 33px;
        }
    }
}
</style>
