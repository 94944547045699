export const selectList = [
    {
        value: 1,
        label: '司机的评价结果公示'
    },
    {
        value: 2,
        label: '咨询投诉处理满意度公示'
    },
    {
        value: 3,
        label: '违规处理结果公示'
    },
    {
        value: 4,
        label: '投诉处理流程公示'
    }
];

export const ruleConfig = {
    scoreInfo: {
        ruleTitle: '货主对司机评价规则',
        ruleText: `
            <p>本评分从“运输效率、运输安全、服务质量、客户满意度”四个维度进行加权算法处理得出。</p>
            <p>1. 运输效率：是否按照合同约定的时间完成。</p>
            <p>2. 服务质量：物流信息完整性（装卸货车照片上传、签收单上传）。</p>
            <p>3. 运输安全：系统对司机驾驶的异常监控以及实际承运人发生交通事故的次数。</p>
            <p>4. 客户满意度：实际承运人被平台货主的有效投诉。</p>
        `
    },
    scoreInfoRate: {
        ruleTitle: '司机投诉满意率计算规则',
        ruleText: `
            <p>1. 用户在平台遇到的各种问题，均可在系统内线上反馈或拨打服务电话010-57376600进行咨询、投诉。</p>
            <p>2. 自平台收到意见和问题后的 24 小时内，向用户电话回访，进行问题的记录和询问；1-3 个工作日内，给出解决方案。</p>
            <p>3. 用户会对每次投诉处理结果进行评价反馈，评价结果包括：非常满意、满意、一般、不满意、非常不满意。</p>
            <p>4. 若用户对投诉结果的处理方式不满意，则可继续拨打平台客服电话，进行反馈和处理。</p>
            <p>5. 平台基于最近30天内的咨询投诉评价结果，加权平均得出平台满意率分数。</p>
            <table class="rule-table">
                <tr>
                    <th class="bg-ddd">评价结果</th>
                    <th>非常满意</th>
                    <th>满意</th>
                    <th>一般</th>
                    <th>不满意</th>
                    <th>非常不满意</th>
                </tr>
                <tr>
                    <td class="bg-ddd">对应分数</td>
                    <td>100</td>
                    <td>80</td>
                    <td>70</td>
                    <td>60</td>
                    <td>40</td>
                </tr>
            </table>
            <h3>平台满意率分数：86</h3>
        `
    },
    scoreInfoDay: {
        ruleTitle: '实际承运人违规处理机制',
        ruleText: `
            <p>驾驶员注册成为网络货运平台会员之后，出现以下情形的，将取消其会员资格： </p>
            <p>1. 驾驶员驾驶证、从业资格证造假； </p>
            <p>2. 驾驶员进入交通运输部超载超限黑名单； </p>
            <p>3. 涉及重大交通责任事故并认定驾驶员负主要责任； </p>
            <p>4. 驾驶员被法院列为失信被执行人；</p>
            <p>5. 评价结果低于60分。</p>
            <p>驾驶员注册成为网络货运平台会员之后，出现以下情形的，平台采取暂停服务若干日：</p>
            <p>1. 每位驾驶员基准分数为上月综合评分，该驾驶员在一个月内每出现“履约情况”、“交通事故”、“用户投诉”情形的，一次扣10分，严重违约的一次扣20分。</p>
            <p>2. 每月计算一次驾驶员综合评分作为司机评分的基准分。</p>
            <p>3. 根据不同的评分，采取暂停服务若干日等管理措施。</p>
            <table class="rule-table">
                <tr>
                    <th class="bg-ddd">评分</th>
                    <th>85-90</th>
                    <th>80-85</th>
                    <th>70-80</th>
                    <th>60-70</th>
                    <th>60以下</th>
                </tr>
                <tr>
                    <td class="bg-ddd">处理结果</td>
                    <td>暂停服务：1日</td>
                    <td>暂停服务：2日</td>
                    <td>暂停服务：7日</td>
                    <td>暂停服务：30日</td>
                    <td>退出</td>
                </tr>
            </table>
        `
    }
};

export const tableConfig = [
    [
        {
            prop: 'name',
            label: '司机姓名',
            align: 'center',
            width: '100px'
        },
        {
            prop: 'phoneNumber',
            label: '手机号',
            align: 'center'
        },
        {
            prop: 'transportEfficiency',
            label: '运输效率',
            align: 'center'
        },
        {
            prop: 'serviceQuality',
            label: '服务质量',
            align: 'center'
        },
        {
            prop: 'transportationSafety',
            label: '运输安全',
            align: 'center'
        },
        {
            prop: 'customerSatisfaction',
            label: '客户满意度',
            align: 'center'
        },
        {
            prop: 'score',
            label: '综合评分',
            align: 'center'
        }
    ],
    [
        {
            prop: 'problemCategory',
            label: '问题类别',
            align: 'center',
            width: '80px'
        },
        {
            prop: 'name',
            label: '司机姓名',
            align: 'center',
            width: '70px'
        },
        // {
        //     prop: 'phoneNumber',
        //     label: '手机号',
        //     align: 'center',
        //     width: '90px'
        // },
        {
            prop: 'problem',
            label: '问题内容',
            align: 'center'
        },
        {
            prop: 'submitTime',
            label: '提交时间',
            align: 'center',
            width: '100px'
        },
        {
            prop: 'replay',
            label: '回复内容',
            align: 'center'
        },
        {
            prop: 'replayTime',
            label: '回复时间',
            align: 'center',
            width: '100px'
        },
        {
            prop: 'satisfaction',
            label: '满意度',
            align: 'center',
            width: '80px'
        }
    ],
    [
        {
            prop: 'name',
            label: '司机姓名',
            align: 'center',
            width: '100px'
        },
        {
            prop: 'phoneNumber',
            label: '手机号',
            align: 'center'
        },
        {
            prop: 'result',
            label: '处理结果',
            align: 'center'
        }
    ]
];
